<template>
    <div>

        <v-card>
            <v-col cols="6" v-if="item.ordinativo.noteconsip">
                <label>Note Non Approvato Consip: </label>
                {{ item.ordinativo.noteconsip }}
            </v-col>
            <div class="float-right ma-3">
                <router-link :to="{name: 'compilazione', params: {id: $route.params.id}}"
                             v-if="!$auth('consip', 'ispettore')">
                    <v-btn color="warning">
                        Modifica
                    </v-btn>
                </router-link>
                <Confirm message="Aggiungi allegato" width="400px" @confirm="uploadRapporto">
                    <template #activator="{on, attrs}">
                        <v-btn v-show="$auth(['amministratore','ispettore']) && item.ordinativo.stato === 'eseguito'"
                               color="success"
                               v-bind="attrs" v-on="on"
                               class="ml-2">
                            Carica Rapporto
                        </v-btn>
                    </template>
                    <template #default>
                        <v-file-input v-model="rapporto" label="Rapporto"></v-file-input>
                    </template>
                </Confirm>
                <v-btn v-if="$auth(['amministratore'])" color="primary" @click="download_rapporto"
                       class="ml-2">
                    Scarica Rapporto
                </v-btn>
                <router-link :to="{name: 'ordinativo_allegati', params: {id: $route.params.id}}"
                >
                    <v-btn v-if="$auth(['amministratore', 'consip', 'ispettore'])" color="primary" class="ml-2">
                        Fatture
                    </v-btn>
                </router-link>
            </div>
            <v-card-title>
                <div>
                    Ordinativo: {{ item.ordinativo.ordine }} - {{ item.ordinativo.descrizione_lotto }}<br>
                    Checklist: {{ item.checklist }} <br>
                </div>
            </v-card-title>
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <label>Data Ispezione: </label> <br>
                        <LocalDate :date="item.ordinativo.data_pianificazione" time></LocalDate>
                    </v-col>
                    <v-col cols="3">
                        <label>Referente: </label> <br>
                        {{ item.ordinativo.referente }} {{ item.ordinativo.stato }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <label>Referente Nome: </label> <br>
                        {{ item.ordinativo.referente_nome }}
                    </v-col>
                    <v-col cols="3">
                        <label>Referente Cognome: </label> <br>
                        {{ item.ordinativo.referente_cognome }}
                    </v-col>
                    <v-col cols="3">
                        <label>Referente E-mail: </label> <br>
                        {{ item.ordinativo.referente_email }}
                    </v-col>
                    <v-col cols="3">
                        <label>Referente Telefono: </label> <br>
                        {{ item.ordinativo.referente_telefono }}
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="3">
                        <label>Penali: </label> <br>
                        <Boolean v-model="item.ordinativo.penali"/>
                    </v-col>
                    <v-col cols="3">
                        <label>Penali Note: </label> <br>
                        {{ item.ordinativo.penali_note }}
                    </v-col>
                    <v-col cols="3">
                        <label>Interrotta: </label> <br>
                        <Boolean v-model="item.ordinativo.interrotta"/>
                    </v-col>
                    <v-col cols="3">
                        Cambio Ordinativo:
                        <Boolean v-model="item.ordinativo.cambio_codice"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <label>Note: </label> <br>
                        {{ item.ordinativo.note }}
                    </v-col>

                </v-row>
            </v-container>

        </v-card>
        <v-card class="mt-2">
            <v-card-title>Timeline / Comunicazioni</v-card-title>
            <v-container>
                <v-row>

                    <template>
                        <v-timeline>
                            <v-timeline-item>

                                <div>
                                    <div class="text-h6">Content title</div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                </div>
                            </v-timeline-item>

                            <v-timeline-item>

                                <div>
                                    <div class="text-h6">Content title</div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                </div>
                            </v-timeline-item>

                            <v-timeline-item>

                                <div>
                                    <div class="text-h6">Content title</div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua.
                                    </p>
                                </div>
                            </v-timeline-item>
                        </v-timeline>
                    </template>
                </v-row>
            </v-container>
        </v-card>
    </div>

</template>

<script>
import Boolean from "@/components/Boolean";
import LocalDate from "@/components/LocalDate";
import Confirm from "@/components/Confirm";

export default {
    data: () => ({
        item: {
            ordinativo: {},
        },
        headers: [
            {text: 'Categoria', value: 'categoria'},
            {text: 'Nome', value: 'nome'},
            {text: 'Livello', value: 'livello'},
            {text: 'Note', value: 'note'},
            {text: 'Allegati', value: 'allegati'},
        ],
        rapporto: null,
    }),
    async mounted() {
        const id = this.$route.params.id;
        const res = await this.$http.get(`/audit/ordinativo/${id}/checklist`);
        if (res.ok) {
            this.item = res.result.data;
        }
    },
    methods: {
        download(id) {
            this.$http.downloadFile(`/checklist/allegato/${id}/download/`);
        },
        async uploadRapporto() {
            await this.$http.postFile(`/audit/ordinativo/${this.$route.params.id}/conferma/`, this.rapporto);
        },
        download_rapporto() {
            this.$http.downloadFile(`/audit/ordinativo/${this.$route.params.id}/download_rapporto/`);
        },
    },
    components: {
        LocalDate,
        Boolean,
        Confirm,
    },
}
</script>
